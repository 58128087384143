<template>
  <!-- 文本答案 -->
  <div class="text-message response">
    <em class="icon-dot"></em>
    <div class="text-message__txt" v-html="message.content"></div>
  </div>
</template>

<script>
export default {
  name: 'BubbleTextResponse',
  props: {
    message: Object
  }
}
</script>

<style lang="scss" scoped>
.icon-dot{
  top: 0.05rem;
  float: left;
  margin-left: -0.01rem;
  margin-right: 0.1rem;
}
.text-message__txt {
  color: #000;
  font-size: 28px;
  line-height: 1.5em;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
</style>
